import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'
 
const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" /> 
                                    About Us
                                </span>
                                <h2>We Help Business to Grow Digitally</h2>
                                <p>We are an experienced IT company who can guide you in any of your digital need. Send us your query for free consultation.</p>
                                
                                <ul className="features-list d-flex p-2">
                                    <li>
                                        <img src={icon4} alt="banner" />
                                        <h3>8 Years</h3>
                                        <p>On the IT market</p>
                                    </li>
                                    <li>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Satisfaction rate</p>
                                    </li>
                                </ul>

                                <Link to="/contact-us" className="default-btn">
                                    <i className="flaticon-right"></i>Contact Us<span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Our Mission</h3>
                                <p>Digitize all the market and help business grow through social media and digital world.</p>
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Digitize all industries</li>
                                    <li><i className="flaticon-tick"></i> Provide Free IT knowledge (via Blogs and consultation)</li>
                                    <li><i className="flaticon-tick"></i> Special support for Startups</li>
                                </ul>
                            </div>
                        </div>
    
                        <div className="col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Who we are</h3>
                                <p>We are an experienced and innovative team to help you grow digitally. We can help you resolve any
                                IT related concerns as well as digital marketing.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Experienced and Innovative</li>
                                    <li><i className="flaticon-tick"></i> We listen to your need</li>
                                    <li><i className="flaticon-tick"></i> Provide ongoing support</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;
